<template>
    <ion-content class="resetPassword">
      <ion-row>
        <ion-col v-if="!passwordChanged">
          <div class="resetPassword__title">
            Nueva contraseña
          </div>
          <div class="resetPassword__form">
            <Form ref="resetPasswordForm" @onSubmit="handleResetPassword">
              <Field name="password1" label="nuevo password" v-slot="{ field, meta }" rules="required|password">
                <Input
                  v-bind="field"
                  :valid="!meta.validated || meta.valid"
                  placeholder="Nuevo password"
                  inputType="password"
                  @change="onChangePassword"
                />
              </Field>
              <ErrorMessage name="password1" v-slot="{ message }">
                <div v-if="message" class="error">{{ message }}</div>
              </ErrorMessage>
              <br/>
              <Field name="password2" label="repite el password" v-slot="{ field, meta }" rules="required|password|confirmed:@password1">
                <Input
                  v-bind="field"
                  :valid="!meta.validated || meta.valid"
                  placeholder="Repite el password"
                  inputType="password"
                  @change="onChangePassword"
                />
              </Field>
              <ErrorMessage name="password2" v-slot="{ message }">
                <div v-if="message" class="error">{{ message }}</div>
              </ErrorMessage>
              <br/>
              <PrimaryButton text="Continuar" @onClick="handleResetPassword" />
            </Form>
          </div>
        </ion-col>
        <ion-col v-else>
          <div class="resetPassword__title">
            Contraseña cambiada
          </div>
          <div class="resetPassword__body">
            Ahora puedes entrar a Fifantasy
          </div>
          <PrimaryButton text="Continuar" @onClick="goToLogin()" />
        </ion-col>
      </ion-row>
    </ion-content>
</template>

<script>
import { ref } from '@vue/reactivity'
import {
  IonContent,
  IonRow,
  IonCol
} from '@ionic/vue'
import Input from "@/components/Input/Input";
import Form from "@/components/Input/Form"
import { Field, ErrorMessage, defineRule } from 'vee-validate'
import { required, confirmed } from "@vee-validate/rules"
import PrimaryButton from '@/components/Button/PrimaryButton.vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
export default {
  components: {
    IonContent,
    IonRow,
    IonCol,

    Input,
    Form,
    Field,
    ErrorMessage,
    PrimaryButton,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const passwordChanged = ref(false)
    const resetPasswordForm = ref()
    const password = ref()

    const passwordValidator = (value) => {
      if (/^(?=.*[A-z])(?=.*\d).{8,}$/.test(value)) {
        return true
      }
      return '8 caracteres mínimo, con al menos una letra y un número'
    }

    const handleResetPassword = () =>  {
      store.commit('showLockScreen')

      resetPasswordForm.value.validate()
        .then(({ valid }) => {
          if (!valid) {
            return
          }
          const data = {
            token: route.params.token,
            password: password.value
          }
          console.log(data)
          store.dispatch('passwordReset', data)
            .then(() => {
              passwordChanged.value = true
            })
        })
        .finally(() => {
          store.commit('hideLockScreen')
        })
    }

    const onChangePassword = (event) =>  {
      password.value = event.target.value
    }

    const goToLogin = () => {
      router.push({ name: 'selectAuth' })
    }

    defineRule("required", required)
    defineRule("confirmed", confirmed)
    defineRule("password", passwordValidator)

    return {
      password,
      resetPasswordForm,
      passwordChanged,

      handleResetPassword,
      onChangePassword,
      goToLogin
    }
  },
}
</script>

<style lang="sass" scoped>
  @import 'ResetPassword.sass'
</style>